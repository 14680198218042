import React from "react";
import classNames from "classnames";
import styles from "./index.module.scss";

type Props = {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  isActive: boolean;
};

const Checkbox = React.memo(function Checkbox({ onClick, isActive }: Props) {
  const checkboxClassName = classNames(styles.checkbox, {
    [styles.active]: isActive
  });
  return <button className={checkboxClassName} onClick={onClick} />;
});

export default Checkbox;
