import React, { ReactNode } from "react";
import classnames from "classnames";
import styles from "./index.module.scss";

type Props = {
  className?: string;
  children?: ReactNode;
};

const Description = ({ className, children }: Props) => (
  <p className={classnames(styles.description, className)}>
    { children}
  </p>
);

export default Description;
