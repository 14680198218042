import React, { ReactNode } from "react";
import styles from "./index.module.scss";

type Props = {
  children: ReactNode;
  closePopup: VoidFunction;
  isCloseButtonDisabled?: boolean;
};

const Popup = ({ children, closePopup, isCloseButtonDisabled }: Props) => {
  return (
    <div className={styles.popup}>
      <div className={styles.popupInner}>
        <button
          className={styles.closeButton}
          onClick={closePopup}
          disabled={isCloseButtonDisabled}
        />
        {children}
      </div>
    </div>
  );
};

export default Popup;
