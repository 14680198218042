import React, { ReactNode } from "react";
import classnames from "classnames";
import styles from "./index.module.scss";

export type Arrows = "greetingDesktop" | "greetingMobile";

type Props = {
  children: ReactNode;
  arrow: Arrows;
  isAnimationStarted: boolean;
};

const Dialog = ({ children, arrow, isAnimationStarted }: Props) => (
  <div className={classnames(styles.dialog, { [styles.animated]: isAnimationStarted }, { [styles.minWidth]: isAnimationStarted})}>
    <div className={classnames(styles.arrow, styles[arrow])} />
    {children}
  </div>
);

export default Dialog;
