import React, { ChangeEvent, useReducer } from "react";
import Popup from "../Popup";
import Status from "../Status";
import Description from "../Description";
import styles from "./index.module.scss";

const SET_STATUS = "SET_STATUS";
const SET_ERROR = "SET_ERROR";

type State = {
  status: string | null;
  src: FileReader["result"];
  error: string | null;
  extraInfo: string | null;
};

type Action =
  | {
      type: typeof SET_STATUS;
      payload: {
        status: State["status"];
        src: State["src"];
        extraInfo: State["extraInfo"]
      };
    }
  | {
      type: typeof SET_ERROR;
      payload: {
        error: State["error"];
      };
    };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case SET_STATUS: {
      return {
        ...state,
        status: action.payload.status,
        extraInfo: action.payload.extraInfo,
        src: action.payload.src,
        error: null
      };
    }
    case SET_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        status: null,
        extraInfo: null,
        src: null
      };
    }
    default:
      return state;
  }
};

type Form = {
  title: string;
  id: string;
  image: string;
};

type ObjectDetection = Form & {
  tags: string[];
};

export type FormTypes = Form | ObjectDetection;

type Props = {
  type: FormTypes;
};

const UploadImageForm = ({ type }: Props) => {
  const { title, id, image } = type;
  const [state, dispatch] = useReducer(reducer, {
    status: null,
    extraInfo: null,
    src: null,
    error: null
  });

  const setStatus = (status: State["status"], src: State["src"], extraInfo: State["extraInfo"]) =>
    dispatch({ type: SET_STATUS, payload: { status, src, extraInfo} });
  const setError = (error: State["error"]) =>
    dispatch({ type: SET_ERROR, payload: { error } });

  const showResult = (status: State["status"], image: Blob, extraInfo: State["extraInfo"]) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(image);
    fileReader.onload = () => {
      setStatus(status, fileReader.result, extraInfo);
    };
  };

  const labelRef = React.createRef<HTMLLabelElement>();

  const resetStatus = () => {
    setStatus(null, null, null);
  };

  const loadImageAgain = () => {
    resetStatus();
    labelRef.current!.click();
  };

  const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const filesList = e.target.files;

    if (!filesList) {
      return;
    }

    const image = filesList[0];

    if (!image) {
      return;
    }

    const maxImageSizeMb = 5;

    const { size } = image;
    const sizeInMb = size / 1024 / 1024;

    if (sizeInMb > maxImageSizeMb) {
      setError("Превышен размер изображения!");
      return;
    }

    const formData = new FormData();
    formData.append("id", id);
    formData.append("image", image);

    try {
      setStatus("Загружаю...", null, null);
      const response = await fetch("/app/execute", {
        method: "POST",
        body: formData
      });
      const parsedResponse = await response.json();
      const { result, extraInfo } = parsedResponse;

      setTimeout(() => {
        setStatus("Что я нашел!", null, null);
      }, 3000);

      setTimeout(() => {
        showResult(result, image, extraInfo);
      }, 6000);
    } catch (error) {
      setError("Ошибка: " + error);
    }
  };

  const renderTags = () => {
    if ("tags" in type) {
      const { tags } = type;
      return (
        <ul className={styles.tags}>
          {tags.map(tag => (
            <li key={tag} className={styles.tag}>
              {tag}
            </li>
          ))}
        </ul>
      );
    }
    return null;
  };

  const renderStatus = () => {
    const { status, src, extraInfo } = state;

    if (!status) {
      return null;
    }

    return (
      <Popup closePopup={resetStatus} isCloseButtonDisabled={!src}>
        <Status
          status={status}
          src={src}
          closePopup={resetStatus}
          loadImageAgain={loadImageAgain}
          extraInfo={extraInfo}
        />
      </Popup>
    );
  };

  const renderError = () => {
    const { error } = state;

    if (!error) {
      return null;
    }

    return <Description className={styles.error}>{error}</Description>;
  };

  return (
    <>
      <form className={styles.form} action="">
        {renderTags()}
        <img className={styles.image} src={image} alt="" />
        <div className={styles.fieldset}>
          <Description className={styles.legend}>{title}</Description>
          <Description className={styles.warn}>
            Минимальный размер каждой стороны – 30 px, максимальный размер JPG
            или PNG файла – 5 Мб
          </Description>
          {renderError()}
          <input
            type="file"
            id={id}
            className={styles.input}
            accept="image/jpeg,image/png"
            onChange={handleChange}
          />
          <label ref={labelRef} className={styles.label} htmlFor={id}>
            Загрузить фото
          </label>
        </div>
      </form>
      {renderStatus()}
    </>
  );
};

export default UploadImageForm;
