import React, { useState } from "react";
import MobileDetect from "mobile-detect";
import Title from "../Title";
import Description from "../Description";
import LineSegment, { LineSegmentMetrics } from "../LineSegment";
import { useWindowSize } from "react-use";
import { ReactComponent as Robbie } from "../../Icons/robbie-small.svg";
import Scheme from "../Scheme";
import styles from "./index.module.scss";

const md = new MobileDetect(window.navigator.userAgent);
const isMobile = md.mobile();
const desktopBreakpoint = 992;

const getLineSegmentMetrics = (width: number): LineSegmentMetrics => {
  if (isMobile || width <= desktopBreakpoint) {
    return {
      width: 6,
      height: 271,
      circles: [[3, 3], [3, 125], [3, 268]],
      points: "3,3 3,268"
    };
  }

  return {
    width: 183,
    height: 197,
    circles: [[3, 3], [80, 98], [160, 193]],
    points: "3,3 3,70 50,70 50,98 80,98 80,165 130,165 130,193 160,193"
  };
};

const getButtonText = (isSchemeOpen: boolean): string => {
  if (isSchemeOpen) {
    return "Скрыть схему";
  }

  return "Посмотреть";
};

const Mechanism = () => {
  const [isSchemeOpen, setSchemeStatus] = useState(false);
  const { width } = useWindowSize();
  const metrics = getLineSegmentMetrics(width);

  const showMoreButtonText = getButtonText(isSchemeOpen);
  const showMoreButtonClick = () => setSchemeStatus(!isSchemeOpen);

  return (
    <section id="mechanism" className={styles.mechanism}>
      <Title title="Как строится работа со мной?" />
      <div className={styles.descriptionContainer}>
        <LineSegment className={styles.lineSegment} metrics={metrics} />
        <Description className={styles.description}>
          Вы передаете контент для обработки моему серверу модерации
          <br /> через API или Apache Kafka
        </Description>
        <Description className={styles.description}>
          Я обрабатываю ваши фото, видео и тексты, затем ставлю тег согласно{" "}
          <br />
          классификации: «хорошо», «плохо», «требует внимания»
        </Description>
        <Description className={styles.description}>
          Обработанный контент приходит обратно к вам <br /> в течение 50
          миллисекунд и готов к публикации
        </Description>
      </div>
      <div className={styles.dialogContainer}>
        <Robbie className={styles.robbie} />
        <div className={styles.dialog}>
          <Description className={styles.dialogDescription}>
            У меня есть подробности
          </Description>
          <button
            onClick={showMoreButtonClick}
            className={styles.showMoreButton}
          >
            {showMoreButtonText}
          </button>
        </div>
      </div>
      {isSchemeOpen && <Scheme />}
    </section>
  );
};

export default Mechanism;
