import React from "react";
import classnames from "classnames";
import styles from "./index.module.scss";

type Props = {
  title?: string;
  description: string | string[];
  topCircles?: boolean;
};

const Base = ({ title, description, topCircles }: Props) => {
  const renderDescription = () => {
    if (Array.isArray(description)) {
      const items = description.map((item, index) => (
        <li
          key={index}
          className={classnames(styles.description, styles.withCircle)}
          dangerouslySetInnerHTML={{ __html: item }}
        />
      ));

      return <ul>{items}</ul>;
    }

    return (
      <p
        className={styles.description}
        dangerouslySetInnerHTML={{ __html: description }}
      />
    );
  };

  const baseClassName = classnames(styles.base, {
    [styles.baseTopCircles]: topCircles
  });

  return (
    <div className={baseClassName}>
      {title && (
        <h2
          className={styles.title}
          dangerouslySetInnerHTML={{ __html: title }}
        />
      )}
      {renderDescription()}
    </div>
  );
};

export default Base;
