import React from "react";
import classnames from "classnames";
import styles from "./index.module.scss";

type Props = {
  title: string;
  className?: string;
};

const Title = ({ title, className }: Props) => (
  <h1 className={classnames(styles.title, className)}>{title}</h1>
);

export default Title;
