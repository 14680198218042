import React from "react";
import Description from "../Description";
import styles from "./index.module.scss";

const schemeData = [
  {
    title: "Пользователь создает",
    description: ["текст, ссылки", " фото,видео", "личные данные"]
  },
  {
    title: "Сервер приложения",
    description: "Передает JSON:HTTPS/Kafka серверу модерации"
  },
  {
    title: "Сервер модерации",
    description: [
      "Классификация",
      "Выделение признаков",
      "Репутация пользователя"
    ]
  },
  {
    title: "Сервер модерации",
    description: [
      "Хороший контент",
      "Плохой контент",
      "Дополнительные метаданные"
    ]
  },
  {
    title: "Сервер приложения",
    description: "Передает клиенту решение о публикации"
  },
  {
    title: "Клиент",
    description: "Публикация или блокировка контента"
  }
];

const Scheme = () => {
  const renderDescription = (description: string | string[]) => {
    if (Array.isArray(description)) {
      return (
        <ul>
          {description.map((item, index) => (
            <li key={index}>
              <Description className={styles.withCircle}>{item}</Description>
            </li>
          ))}
        </ul>
      );
    }

    return <Description>{description}</Description>;
  };

  return (
    <>
      <Description className={styles.mainTitle}>
        Схема взаимодействия
      </Description>
      <ul className={styles.list}>
        {schemeData.map((item, index) => {
          const { title, description } = item;

          return (
            <li key={index} className={styles.item}>
              <div className={styles.contentContainer}>
                <Description className={styles.title}>{title} </Description>
                {renderDescription(description)}
              </div>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default Scheme;
