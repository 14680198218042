import React from "react";
import Description from "../Description";
import eyes from "./eyes.png";
import styles from "./index.module.scss";

type Props = {
  status: string;
  src?: any;
  closePopup?: VoidFunction;
  loadImageAgain?: VoidFunction;
  extraInfo?: any;
};

const Status = ({ status, src, closePopup, loadImageAgain, extraInfo }: Props) => {
  if (src) {
    return (
      <div className={styles.status}>
        <img
          className={styles.uploadedImage}
          src={src}
          alt="Загруженное изображение"
        />
        <Description className={styles.result}>{status}</Description>
        <Description className={styles.result}>{extraInfo}</Description>
        <div className={styles.controlsContainer}>
          <button className={styles.tryAgainButton} onClick={loadImageAgain}>
            Загрузить еще
          </button>
          <button className={styles.closePopupButton} onClick={closePopup}>
            Закрыть
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.status}>
      <img className={styles.image} src={eyes} alt="" />
      <Description className={styles.description}>{status}</Description>
    </div>
  );
};

export default Status;
