import React from "react";
import classNames from "classnames";
import { useForm } from "react-hook-form";
import MaskedInput from "react-input-mask";
import styles from "./index.module.scss";
import Status from "../Status";
import Loader from "../Loader";
import Checkbox from "../Checkbox";
// @ts-ignore
import Agreement from "../../agreement.pdf";

type Inputs = {
  name: string;
  phone: string;
  email: string;
  comment: string;
  company?: string;
};

type Props = {
  closeForm: () => void;
};

const RequestForm = ({ closeForm }: Props) => {
  const [phone, setPhone] = React.useState<string>("");
  const { register, handleSubmit, errors } = useForm<Inputs>();
  const [status, setStatus] = React.useState("");
  const [isLoading, setLoading] = React.useState<boolean>(false);
  const [isConfirmed, setConfirmed] = React.useState<boolean>(false);

  const onSubmit = async (form: Inputs) => {
    setLoading(true);

    try {
      const response = await fetch("/app/contact", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(form)
      });
      const parsedResponse = await response.json();

      if (parsedResponse.result === "ok") {
        setStatus("Данные успешно отправлены");
      } else {
        setStatus("Попробуйте еще раз");
      }
    } catch (error) {
      console.error(error);
      setStatus("Попробуйте еще раз");
    } finally {
      setLoading(false);
    }
  };

  if (isLoading) {
      return (
          <div className={styles.container}>
              <Loader />
          </div>
      );
  }

  if (status) {
    return <Status status={status} />;
  }

  const isSubmitButtonDisabled = !isConfirmed;
  const toggleConfirmStatus = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setConfirmed(!isConfirmed);
  };

  return (
    <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
      <h3 className={styles.formTitle}>Оставить заявку</h3>
      <div className={styles.formField}>
        <label className={styles.label}>Как к Вам можно обращаться?*</label>
        <input
          className={styles.input}
          name="name"
          ref={register({ required: true })}
          placeholder="Напишите, как к вам можно обращаться"
          style={{ borderColor: errors.name ? "red" : "" }}
        />
        {errors.name && <p className={styles.error}>Напишите ваше имя</p>}
      </div>

      <div className={styles.fieldsRow}>
        <div className={styles.formFieldHalf}>
          <label className={styles.label}>Email для ответа*</label>
          <input
            className={styles.input}
            name="email"
            ref={register({ required: true, pattern: /^\S+@\S+$/i })}
            placeholder="exampleemail@mail.ru"
            style={{ borderColor: errors.email ? "red" : "" }}
          />
          {errors.email && <p className={styles.error}>Напишите ваш email</p>}
        </div>

        <div className={styles.formFieldHalf}>
          <div>
            <label className={styles.label}>Телефон</label>
            <MaskedInput
              className={styles.input}
              value={phone}
              name="phone"
              inputRef={register({ required: false })}
              mask="+7 (999) 999-99-99"
              placeholder="+7 (000) 000-00-00"
              onChange={e => setPhone(e.target.value)}
              style={{ borderColor: errors.phone ? "red" : "" }}
            />
            {errors.phone && (
              <p className={styles.error}>{errors.phone.message}</p>
            )}
          </div>
        </div>
      </div>

      <div className={styles.formField}>
        <label className={styles.label}>Название компании</label>
        <input
          name="company"
          className={styles.input}
          placeholder="Напишите, как к вам можно обращаться"
          ref={register({ required: false })}
        />
      </div>

      <div className={styles.formField}>
        <label className={styles.label}>Комментарий*</label>
        <textarea
          className={styles.textarea}
          name="comment"
          ref={register({ required: true })}
          placeholder="Опишите максимально подробно ваши проекты и задачи"
          style={{ borderColor: errors.comment ? "red" : "" }}
        ></textarea>
        {errors.comment && (
          <p className={styles.error}>
            Напишите название компании и сферу деятельности
          </p>
        )}
      </div>
      <div className={classNames(styles.formField, styles.formFieldInline)}>
        <Checkbox onClick={ toggleConfirmStatus } isActive={ isConfirmed } />
        <p className={styles.label}>Я согласен(на) на <a className={styles.link} href={ Agreement } target="_blank">обработку персональных данных</a>*</p>
      </div>
      <button type="submit" className={styles.submitButton} disabled={ isSubmitButtonDisabled }>
        Отправить
      </button>
    </form>
  );
};

export default RequestForm;
