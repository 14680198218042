import React from "react";
import Title from "../Title";
import Description from "../Description";
import classNames from "classnames";
import styles from "./index.module.scss";

const Prices = () => {
  return (
    <section id="prices" className={styles.prices}>
      <Title title="Цены на обработку" />
      <Description className={styles.description}>
        Автоматическая обработка данных, стоимость обработки за 1 единицу информации
      </Description>
      <ul className={styles.priceList}>
        <li className={styles.listHeader}>
          <div className={styles.container}>
            <Description className={styles.descriptionSmall}>
              Модель
            </Description>
          </div>
          <div className={styles.innerContainer}>
            <Description className={styles.descriptionSmall}>
              единиц информации в месяц
            </Description>
            <Description className={styles.descriptionSmall}>
              0,1 млн – 1 млн
            </Description>
            <Description className={styles.descriptionSmall}>
              1 млн – 10 млн
            </Description>
            <Description className={styles.descriptionSmall}>
              10 млн – 100 млн
            </Description>
          </div>
        </li>
        <li className={styles.item}>
          <Description className={styles.itemTitle}>
            Дедупликация phash
          </Description>
          <Description className={styles.itemDescription}>0,4 ₽</Description>
          <Description className={styles.itemDescription}>0,3 ₽</Description>
          <Description className={styles.itemDescription}>0,2 ₽</Description>
        </li>
        <li className={styles.item}>
          <Description className={styles.itemTitle}>Контент 18+</Description>
          <Description className={styles.itemDescription}>0,4 ₽</Description>
          <Description className={styles.itemDescription}>0,3 ₽</Description>
          <Description className={styles.itemDescription}>0,2 ₽</Description>
        </li>
        <li className={styles.item}>
          <Description className={styles.itemTitle}>План квартиры</Description>
          <Description className={styles.itemDescription}>0,4 ₽</Description>
          <Description className={styles.itemDescription}>0,3 ₽</Description>
          <Description className={styles.itemDescription}>0,2 ₽</Description>
        </li>
        <li className={styles.item}>
          <Description className={styles.itemTitle}>Распознавание текста с картинки</Description>
          <Description className={styles.itemDescription}>0,5 ₽</Description>
          <Description className={styles.itemDescription}>0,4 ₽</Description>
          <Description className={styles.itemDescription}>0,3 ₽</Description>
        </li>
        <li className={styles.item}>
          <Description className={styles.itemTitle}>
            Дедупликация моделями нейронных сетей
          </Description>
          <Description className={styles.itemDescription}>0,5 ₽</Description>
          <Description className={styles.itemDescription}>0,4 ₽</Description>
          <Description className={styles.itemDescription}>0,3 ₽</Description>
        </li>
      </ul>
      <Description className={styles.description}>
        Ручная обработка данных, стоимость за обработку 1000 единицы информации
      </Description>
      <ul className={styles.priceList}>
        <li className={classNames(styles.item, styles.itemSmall)}>
          <Description className={styles.itemTitle}>
            Фото
          </Description>
          <Description className={styles.itemDescription}> от 110 ₽</Description>
        </li>
        <li className={classNames(styles.item, styles.itemSmall)}>
          <Description className={styles.itemTitle}>
            Видео
          </Description>
          <Description className={styles.itemDescription}> от 370 ₽</Description>
        </li>
        <li className={classNames(styles.item, styles.itemSmall)}>
          <Description className={styles.itemTitle}>
            Тексты
          </Description>
          <Description className={styles.itemDescription}> от 260 ₽</Description>
        </li>
        <li className={classNames(styles.item, styles.itemSmall)}>
          <Description className={styles.itemTitle}>
            Аудио
          </Description>
          <Description className={styles.itemDescription}> от 2630 ₽</Description>
        </li>
      </ul>
    </section>
  );
};

export default Prices;
