import React, {useEffect, useState} from "react";
import Greeting from "./Components/Greeting";
import PlatformBase from "./Components/PlatformBase";
import Advantages from "./Components/Advantages";
import Cases from "./Components/Cases";
import Users, { Clients } from "./Components/Users";
import NeuralModelsForms, { Forms } from "./Components/NeuralModelsForms";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import Loader from "./Components/Loader";
import Mechanism from "./Components/Mechanism";
import Popup from "./Components/Popup";
import RequestForm from "./Components/RequestForm";
import Prices from "./Components/Prices";

type State = {
  forms: Forms | null;
  clients: Clients | null;
  isLoading: boolean;
  isContactFormOpened: boolean;
};

type SuccessResponse = {
  forms: NonNullable<State["forms"]>;
  clients: NonNullable<State["clients"]>;
};

const App = () => {
  const [state, setState] = useState<State>({
    forms: null,
    clients: null,
    isLoading: true,
    isContactFormOpened: false
  });

  useEffect(() => {
    fetch("/app/config")
      .then(response => response.json())
      .then((data: SuccessResponse) => {
        const { forms, clients } = data;
        setState({ ...state, forms, clients, isLoading: false });
      })
      .catch(() => {
        setState({ ...state, isLoading: false });
      });
  }, []);

  const { isLoading } = state;

  if (isLoading) {
    return <Loader />;
  }

  const { forms, isContactFormOpened } = state;
  const handleToggleContactForm = () =>
    setState({ ...state, isContactFormOpened: !state.isContactFormOpened });


  return (
    <>
      <Header openForm={handleToggleContactForm} />
      <Greeting />
      <PlatformBase />
      <Advantages />
      <Mechanism />
      <Cases />
      {forms && <NeuralModelsForms forms={forms} />}
      <Users />
      <Prices />
      {isContactFormOpened && (
        <Popup closePopup={handleToggleContactForm}>
          <RequestForm closeForm={handleToggleContactForm} />
        </Popup>
      )}
      <Footer openForm={handleToggleContactForm} />
    </>
  );
};

export default App;
