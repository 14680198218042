const useSmoothScroll = () => {
  return (id: string) => {
    const element = document.getElementById(id);
    const headerHeight = document.getElementById("header")!.offsetHeight;

    if (!element || !headerHeight) {
      return;
    }

    const topOfElement = element.offsetTop - headerHeight;
    window.scroll({ top: topOfElement, behavior: "smooth" });
  };
};

export default useSmoothScroll;
