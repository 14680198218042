import React from "react";
import Title from "../Title";
import Description from "../Description";
import Ok from "../../Icons/clients-ok.svg";
import MyTarget from "../../Icons/clients-my-target.svg";
import Yula from "../../Icons/clients-yula.svg";
import Messengers from "../../Icons/clients-messengers.svg";
import Taxi from "../../Icons/clients-taxi.svg";
import Games from "../../Icons/clients-games.svg";
import styles from "./index.module.scss";

export type Clients = Client[];

type Client = {
  image: string;
  title: string;
};

const clients = [
    {
        title: "Социальная сеть",
        image: Ok
    },
    {
        title: "Рекламная платформа",
        image: MyTarget
    },
    {
        title: "Сервис объявлений",
        image: Yula
    },
    {
        title: "Мессенджеры",
        image: Messengers
    },
    {
        title: "Агрегатор такси",
        image: Taxi
    },
    {
        title: "ММО игры",
        image: Games
    }
];


const Users = () => {
  const renderClients = () =>
    clients.map(client => {
      const { image, title } = client;

      return (
        <li key={title} className={styles.item}>

          <div
            className={styles.user}
            style={{ backgroundImage: `url(${image})` }}
          >
            <Description className={styles.userDescription}>
              {title}
            </Description>
          </div>
        </li>
      );
    });

  return (
    <section className={styles.users}>
      <Title title="Кто уже использует" />
      <ul className={styles.list}>{renderClients()}</ul>
    </section>
  );
};

export default Users;
