import React, { useRef, useState } from "react";
import classnames from "classnames";
import MobileDetect from "mobile-detect";
import { useWindowScroll, useWindowSize } from "react-use";
import Title from "../Title";
import Base from "../Base";
import LineSegment, { LineSegmentMetrics } from "../LineSegment";
import { desktopBreakpoint } from "../../consts/media";
// @ts-ignore
import Neiro from "../../neiro.mp4";
// @ts-ignore
import Neiro2x from "../../neiro@2x.mp4";
import styles from "./index.module.scss";

type SVGMetrics = Record<
  "neuralModels" | "service" | "moderators",
  LineSegmentMetrics
>;

const PlatformBase = () => {
  const { y } = useWindowScroll();
  const platformBaseRef = useRef<HTMLTableSectionElement>(null);

  const { width } = useWindowSize();

  const getSVGMetrics = (): SVGMetrics => {
    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = md.mobile();

    if (isMobile || width <= desktopBreakpoint) {
      return {
        neuralModels: {
          width: 84,
          height: 49,
          // координаты представлены в виде [x, y]
          circles: [[3, 3], [81, 46]],
          points: "3,3 55,3 55,20 81 ,20 81,46"
        },
        service: {
          width: 53,
          height: 14,
          circles: [[3, 11], [50, 3]],
          points: "3,11 32,11 32,3 50,3"
        },
        moderators: {
          width: 76,
          height: 33,
          circles: [[3, 30], [73, 3]],
          points: "3,30 30,30 30,3 73,3"
        }
      };
    }

    return {
      neuralModels: {
        width: 100,
        height: 25,
        circles: [[3, 3], [97, 22]],
        points: "3,3 50,3 50,22 97,22"
      },
      service: {
        width: 80,
        height: 60,
        circles: [[3, 57], [77, 3]],
        points: "3,57 40,57 40,3 80,3"
      },
      moderators: {
        width: 80,
        height: 60,
        circles: [[3, 3], [77, 57]],
        points: "3,3 40,3 40,57 80,57"
      }
    };
  };

  const { neuralModels, service, moderators } = getSVGMetrics();

  const platformBaseClass = () => {
    const { current } = platformBaseRef;

    if (!current) {
      return styles.platformBase;
    }

    const { offsetTop } = current;
    const center = offsetTop / 2;

    if (current.classList.contains(styles.platformBaseWithAnimation)) {
      return classnames(styles.platformBase, styles.platformBaseWithAnimation);
    }

    if (center <= y) {
      return classnames(styles.platformBase, styles.platformBaseWithAnimation);
    }
  };

  return (
    <section className={platformBaseClass()} ref={platformBaseRef}>
      <div className={styles.contentContainer}>
        <Title className={styles.title} title="Что мне помогает в работе?" />
        <div className={styles.neuralModels}>
          <Base
            title="Модели нейронных сетей, <br> работающие со следующими <br> типами контента:"
            description={[
              "изображения/гиф",
              "видео",
              "текстовая информация",
              "URL-ссылки"
            ]}
          />
          <LineSegment className={styles.lineSegment} metrics={neuralModels} />
        </div>
        <div className={styles.service}>
          <Base
            title="Сервис краудсорсинга"
            description="Для ручной разметки <br /> больших объемов данных"
          />
          <LineSegment className={styles.lineSegment} metrics={service} />
        </div>
        <div className={styles.moderators}>
          <Base
            title="Внутренние модераторы"
            description="Для разметки <br /> специфических задач"
          />
          <LineSegment className={styles.lineSegment} metrics={moderators} />
        </div>
        <video autoPlay preload="auto" loop className={styles.video} muted>
          <source type='video/mp4' src={ Neiro2x } media='(min-device-pixel-ratio:2), (-webkit-min-device-pixel-ratio:2), (min--moz-device-pixel-ratio:2), (-o-min-device-pixel-ratio:2)' />
          <source type='video/mp4' src={ Neiro } media='(max-device-pixel-ratio:1), (-webkit-max-device-pixel-ratio:1), (max--moz-device-pixel-ratio:1), (-o-max-device-pixel-ratio:1)' />
        </video>
      </div>
    </section>
  );
};

export default PlatformBase;
