import React, { useState } from "react";
import Title from "../Title";
import Description from "../Description";
import Card from "../Card";
import Popup from "../Popup";
import deduplication from "./deduplication.png";
import cars from "./cars.png";
import prohibitedItems from "./prohibitedItems.png";
import adult from "./adult.png";
import artifacts from "./artifacts.png";
import text from "./text.png";
import styles from "./index.module.scss";

const cases = [
  {
    className: styles.copy,
    title: "Дедупликация",
    description: "Простая, нейронные сети, с помощью распознанного текста",
    url: deduplication
  },
  {
    className: styles.car,
    title: "Автомобили",
    description: "Марка, цвет",
    url: cars
  },
  {
    className: styles.photo,
    title: "Негативные изображения",
    description: "Контент 18+, сцены насилия, спам",
    url: adult
  },
  {
    className: styles.block,
    title: "Запрещенные товары",
    description:
      "Огнестрельное и холодное оружие, товары 18+, кальяны, алкоголь",
    url: prohibitedItems
  },
  {
    className: styles.cover,
    title: "Артефакты на фото",
    description: "Коллажи, рамки, управляющие элементы, URL-ссылки, телефон",
    url: artifacts
  },
  {
    className: styles.text,
    title: "Распознавание текста",
    description:
      "Документы, спам, мошенничество, нецензурные выражения, буллинг",
    url: text
  }
];

type Example = {
  title: string;
  url: string;
};

const Cases = () => {
  const [example, setExample] = useState<Example>({ title: "", url: "" });

  const handleCloseExample = () => setExample({ title: "", url: "" });
  const handleSetExample = (title: Example["title"], url: Example["url"]) =>
    setExample({ title, url });

  const renderCases = () =>
    cases.map((item, index) => {
      const { title, description, className, url } = item;

      return (
        <Card
          className={styles.card}
          key={index}
          iconClassName={className}
          title={title}
          description={description}
          clickable
          onClick={() => handleSetExample(title, url)}
        />
      );
    });

  const { title, url } = example;

  return (
    <section id="cases" className={styles.cases}>
      <Title title="Готовые кейсы" />
      {renderCases()}
      {title && url && (
        <Popup closePopup={handleCloseExample}>
          <div className={styles.example}>
            <Description className={styles.exampleDescription}>
              {title}
            </Description>
            <img className={styles.exampleImage} src={url} alt={title} />
          </div>
        </Popup>
      )}
    </section>
  );
};

export default Cases;
