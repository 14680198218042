import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { useWindowScroll } from "react-use";
import MobileDetect from "mobile-detect";
import { ReactComponent as RobbiLogo } from "../../Icons/robbi_logo.svg";
import { ReactComponent as OkLogo } from "../../Icons/ok_logo.svg";
import Description from "../Description";
import styles from "./index.module.scss";

const Logo = () => {
  return (
      <div className={styles.logo}>
        <RobbiLogo />
        <span />
        <OkLogo />
      </div>
  );
};

const items = [
  {
    title: "Платформа",
    id: "platform"
  },
  {
    title: "Преимущества",
    id: "advantages"
  },
  {
    title: "Механизм",
    id: "mechanism"
  },
  {
    title: "Кейсы",
    id: "cases"
  },
  {
    title: "Стоимость",
    id: "prices"
  },
  {
    title: "Контакты",
    id: "contacts"
  }
];

type Props = {
  openForm: VoidFunction;
};

const Header = ({ openForm }: Props) => {
  const md = new MobileDetect(window.navigator.userAgent);
  const isMobile = md.mobile();

  const headerRef = useRef<HTMLElement>(null);
  const [headerHeight, setHeaderHeight] = useState(0);

  const [isNavigateOpen, setNavigateStatus] = useState(false);
  const { y } = useWindowScroll();

  useEffect(() => {
    setHeaderHeight(headerRef.current!.offsetHeight);
  }, [headerRef]);

  const headerClassNames = classNames(styles.header, {
    [styles.withBackground]: y > 0 || isMobile,
    [styles.headerNavigateOpened]: isNavigateOpen && isMobile
  });

  const toggleNavigateHandler = () => setNavigateStatus(!isNavigateOpen);

  const scrollToElement = (id: string) => {
    const element = document.getElementById(id);

    if (!element) {
      return;
    }

    if (window.scroll) {
      const topOfElement = element.offsetTop - headerHeight;
      window.scroll({ top: topOfElement, behavior: "smooth" });
      return;
    }

    element.scrollIntoView({ block: "center" });
  };

  const itemHandlerClick = (id: string) => {
    isMobile && toggleNavigateHandler();

    scrollToElement(id);
  };

  // const getNavigateButtonClass = (id: string) => {
  //   const item = document.getElementById(id);
  //
  //   if (!item) {
  //     return styles.navigateButton;
  //   }
  //
  //   const { offsetTop } = item;
  //   const yOfElement = offsetTop - headerHeight - 50;
  //
  //   const diff = offsetTop + item.offsetHeight - headerHeight - 50 - y;
  //   console.log(diff, "DIFF");
  //
  //   if (diff <= -100) {
  //     return styles.navigateButton;
  //   }
  //
  //   if (yOfElement <= y) {
  //     return classNames(styles.navigateButton, styles.navigateButtonActive);
  //   }
  //
  //   return styles.navigateButton;
  // };

  const renderItems = () =>
    items.map(item => {
      return (
        <li key={item.title}>
          <button
            className={styles.navigateButton}
            onClick={() => itemHandlerClick(item.id)}
          >
            {item.title}
          </button>
        </li>
      );
    });

  const year = new Date().getFullYear();

  return (
    <header id="header" className={headerClassNames} ref={headerRef}>
      <div className={styles.controlsContainer}>
        <button
          className={styles.logoButton}
          onClick={() => scrollToElement("platform")}
          title="Привет, я Робби"
        >
          <Logo />
        </button>
        <button
          className={styles.openMenuButton}
          onClick={toggleNavigateHandler}
        >
          <div className={styles.line} />
          <div className={styles.line} />
          <div className={styles.line} />
        </button>
      </div>

      <nav className={styles.navigate}>
        <ul className={styles.list}>
          {renderItems()}
          <li>
            <button className={styles.button} onClick={openForm}>
              Написать
            </button>
          </li>
        </ul>
      </nav>
      <div className={styles.colonTitle}>
        <Description>© Робби, {year}</Description>
        <Description>от проекта Одноклассники"</Description>
      </div>
    </header>
  );
};

export default Header;
