import React from "react";
import Description from "../Description";
import { ReactComponent as Robbie } from "../../Icons/robbie-small.svg";
import styles from "./index.module.scss";

type Props = {
  openForm: VoidFunction;
}

const Footer = ({ openForm }: Props) => {
  const year = new Date().getFullYear();
  return (
    <footer id="contacts" className={styles.footer}>
      <div className={styles.gradientContainer}>
        <div className={styles.contact}>
          <Description className={styles.description}>
            Напишите мне, если нужна помощь
          </Description>
          <button
            className={styles.contactButton}
            onClick={openForm}
          >
            Связаться с Робби
          </button>
        </div>
        <div className={styles.contentContainer}>
          <div className={styles.innerContentContainer}>
            <div className={styles.robbieContainer}>
              <Robbie className={styles.robbieIcon} />
              <div>
                <Description className={styles.title}>Робби</Description>
                <Description>
                  Классификация и модерация контента
                </Description>
              </div>
            </div>
            {/*<ul className={styles.list}>*/}
            {/*  <li className={styles.item}>*/}
            {/*    <a className={styles.link} href="#" />*/}
            {/*  </li>*/}
            {/*  <li className={styles.item}>*/}
            {/*    <a className={styles.link} href="#" />*/}
            {/*  </li>*/}
            {/*  <li className={styles.item}>*/}
            {/*    <a className={styles.link} href="#" />*/}
            {/*  </li>*/}
            {/*  <li className={styles.item}>*/}
            {/*    <a className={styles.link} href="#" />*/}
            {/*  </li>*/}
            {/*</ul>*/}
          </div>
        </div>
      </div>
      <div className={styles.colonTitle}>
        <Description className={styles.colonTitleText}>
          © Робби, {year}
        </Description>
        <Description className={styles.colonTitleText}>
          от проекта Одноклассники
        </Description>
      </div>
    </footer>
  );
};

export default Footer;
