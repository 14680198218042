import React from "react";
import Title from "../Title";
import UploadImageForm, { FormTypes } from "../UploadImageForm";
import styles from "./index.module.scss";

export type Forms = FormTypes[];

type Props = {
  forms: Forms;
};

const NeuralModelsForms = ({ forms }: Props) => {
  const renderForms = () =>
    forms.map(form => <UploadImageForm key={form.id} type={form} />);

  return (
    <section id="neuralModelsForms" className={styles.section}>
      <Title title="Хотите попробовать?" />
      <div className={styles.formsContainer}>{renderForms()}</div>
    </section>
  );
};

export default NeuralModelsForms;
