import React from "react";
import classnames from "classnames";
import Description from "../Description";
import styles from "./index.module.scss";

type SimpleCard = {
  className: string;
  iconClassName: string;
  title: string;
  description: string;
};

type ClickableCard = SimpleCard & {
  clickable: boolean;
  onClick: VoidFunction;
};

type Props = SimpleCard | ClickableCard;

const Card = (props: Props) => {
  const { className, iconClassName, title, description } = props;

  if ("clickable" in props) {
    const { onClick } = props;

    return (
      <div
        onClick={onClick}
        className={classnames(className, styles.clickable)}
      >
        <div className={classnames(iconClassName, styles.icon)} />
        <h3 className={styles.title}>{title}</h3>
        <Description>{description}</Description>
        <p className={styles.examples}>Примеры</p>
      </div>
    );
  }

  return (
    <div className={className}>
      <div className={classnames(iconClassName, styles.icon)} />
      <h3 className={styles.title}>{title}</h3>
      <Description>{description}</Description>
    </div>
  );
};

export default Card;
