import React from "react";
import styles from "./index.module.scss";

export type LineSegmentMetrics = {
  width: number;
  height: number;
  circles: [number, number][];
  points: string;
};

type Props = {
  className: string;
  metrics: LineSegmentMetrics;
};

const LineSegment = ({
  metrics: { width, height, circles, points },
  className
}: Props) => {
  const renderCircles = () =>
    circles.map((circle, index) => (
      <circle
        key={index}
        className={styles.circle}
        cx={circle[0]}
        cy={circle[1]}
        r="3"
      />
    ));

  return (
    <svg
      className={className}
      width={width}
      height={height}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      {renderCircles()}
      <polyline className={styles.polyline} points={points} />
    </svg>
  );
};

export default LineSegment;
