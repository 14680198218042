import React from "react";
import Title from "../Title";
import Card from "../Card";
import styles from "./index.module.scss";

const advantages = [
  {
    className: styles.like,
    title: "Накопленная экспертиза",
    description:
      "Готовые решения для вашего бизнеса, собранные благодаря широкой экспертизе работы с партнерами Робби"
  },
  {
    className: styles.light,
    title: "Высокая скорость ответа",
    description:
      "Высокая скорость ответа по API для задач компьютерного зрения — от 50 миллисекунд"
  },
  {
    className: styles.vip,
    title: "Индивидуальные решения",
    description:
      "Возможность быстрого создания индивидуальных проектов под конкретные задачи компании"
  },
  {
    className: styles.check,
    title: "Простая интеграция",
    description: "Простая интеграция в системы управления бизнесом"
  },
  {
    className: styles.money,
    title: "Низкая стоимость",
    description: "Низкая стоимость обработки контента"
  }
];

const Advantages = () => {
  const renderAdvantages = () =>
    advantages.map((advantage, index) => {
      const { title, description, className } = advantage;

      return (
        <Card
          key={index}
          className={styles.card}
          iconClassName={className}
          title={title}
          description={description}
        />
      );
    });

  return (
    <section id="advantages" className={styles.advantages}>
      <Title title="Преимущества" />
      {renderAdvantages()}
    </section>
  );
};

export default Advantages;
