import React, { useState } from "react";
import { useWindowSize } from "react-use";
import classnames from "classnames";
import Typed from "react-typed";
import MobileDetect from "mobile-detect";
import Description from "../Description";
import { ReactComponent as Robbie } from "../../Icons/robbie.svg";
import Dialog, { Arrows } from "../Dialog";
import { desktopBreakpoint } from "../../consts/media";
import useSmoothScroll from "../../customHooks/useSmoothScroll";
import Title from "../Title";
import Base from "../Base";
import styles from "./index.module.scss";

const Greeting = () => {
  const [isAllTextShowed, showAllText] = useState(false);

  const md = new MobileDetect(window.navigator.userAgent);
  const isMobile = md.mobile();
  const { width } = useWindowSize();

  const getGreetingDialogArrow = (): Arrows => {
    if (isMobile || width <= desktopBreakpoint) {
      return "greetingMobile";
    }

    return "greetingDesktop";
  };

  const renderTitle = () => {
    if (isMobile || width <= desktopBreakpoint) {
      return <Title title={"Привет, я Робби!"} className={styles.title} />;
    }

    const onComplete = () => showAllText(true);

    return (
      <Typed
        className={styles.title}
        strings={["Привет, я Робби!"]}
        typeSpeed={80}
        showCursor={false}
        onComplete={onComplete}
        startDelay={1300}
      />
    );
  };

  const onClick = useSmoothScroll();

  return (
    <section id="platform" className={styles.greeting}>
      <div className={styles.contentContainer}>
        <Robbie className={styles.robbie} />
        <div className={styles.dialogContainer}>
          <Dialog
            isAnimationStarted={isAllTextShowed}
            arrow={getGreetingDialogArrow()}
          >
            {renderTitle()}
            <Description
              className={classnames(styles.description, {
                [styles.descriptionAnimated]: isAllTextShowed
              })}
            >
              Я — искусственный интеллект по обработке контента, разработанный командой Одноклассников.<br /> Помогаю
              анализировать и классифицировать gif-изображения, фото, видео и
              тексты. Со мной вы сможете обрабатывать большие объемы информации
              за считанные миллисекунды.
            </Description>
            <button
              className={classnames(styles.tryButton, {
                [styles.tryButtonAnimated]: isAllTextShowed
              })}
              onClick={() => onClick("neuralModelsForms")}
            >
              Попробовать
            </button>
          </Dialog>
        </div>
      </div>
      <Description className={styles.subDescription}>
        Меня называют автоматизированной платформой для анализа контента на базе
        нейросетей и технологии big data.
      </Description>
      <Description className={styles.subDescription}>
        Я обрабатываю пользовательский контент с помощью специальных
        анализаторов текста и компьютерного зрения:
      </Description>
      <div className={styles.listContainer}>
        <Base
          topCircles={true}
          description={[
            "нахожу запрещенные слова и изображения",
            "распознаю объекты на фото и видео",
            "идентифицирую спам, ссылки, телефонные номера",
            'оптимизирую работу с объявлениями и комментариями'
          ]}
        />
      </div>
    </section>
  );
};

export default Greeting;
